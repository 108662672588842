import React, {useState, useEffect, useRef} from 'react';
import { getColumns, saveEntry, EntryRow} from "./db"
import { getTransformFunc, splitWord, submitKeyword } from './columnTransform';
import { useAuth } from "./AuthContext"
import FormInput from './components/form_input';
import { BARCODE_COLUMN, FIXED_BARCODE_COLUMN } from './common';
import BarcodeInput from './components/barcode_input';
import { Item, BarcodeItem} from "./type_declarations";
import getTranslation from "./translations";

function App() {
  const {currentUser} = useAuth();
  const [inputText, setInputText] = useState("")
  const [columns, setColumns] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);
  const footerRef = useRef<HTMLTextAreaElement>(null);
  const dataFromColumns = (cols: Item[]) => {
    const data = cols.map((c) => ({"identifier": c.name.replaceAll(" ", "_"), "name": c.name, "value": "", "top": -1}));
    return {data: data, creationDate: new Date().toISOString()}
  }
  const [data, setData] = useState<EntryRow>(new EntryRow())
  const [fixedBarcode, setBarcode] = useState<BarcodeItem>();
  const [secondBarcode, setSecondBarcode] = useState<BarcodeItem>();

  useEffect(() => {
    getColumns(currentUser, (columns: Item[]) =>{
      let filteredColumns = [];

      for(let i=0;i<columns.length;i++){
        let column = columns[i];
        if(column.type === FIXED_BARCODE_COLUMN){
          setBarcode({...column as BarcodeItem})
        } else if(column.type === BARCODE_COLUMN){
          setSecondBarcode({...column as BarcodeItem})
        } else {
          filteredColumns.push(column)
        }
      }

      setColumns(filteredColumns)
      setData(dataFromColumns(filteredColumns))
      setLoading(false)
    });
  },[])

  const onChange = (e:any) =>{
    let inputText = (e.target.value as String).toLowerCase()
    let shouldSubmit = false

    //set top only if we have focus
    const top = footerRef.current && document.activeElement === e.target ? footerRef.current.getBoundingClientRect().top : 0;
    if(inputText.endsWith(submitKeyword)){
      shouldSubmit = true
      inputText = inputText.substring(0, inputText.length - submitKeyword.length)
    }

    const text = inputText.split(splitWord);
    let newData = dataFromColumns(columns);
    for(let i=0; i<text.length;i++){
      if(i < columns.length){
        let transformFunc = getTransformFunc(columns[i].type)
        newData.data[i]['value'] = transformFunc(text[i].trim())
        newData.data[i]['top'] = top;
      } else {
        let transformFunc = getTransformFunc(columns[columns.length -1].type)
        newData.data[columns.length - 1]['value'] = newData.data[columns.length - 1]['value']  + " | " + transformFunc(text[i].trim());
        newData.data[columns.length - 1]['top'] = top;
      }
    }

    if(shouldSubmit){
      saveAndReset(newData)
    } else {
      setData(newData)
      setInputText(e.target.value)
    }
  }

  const manualChange = (newValue: string, identifier: string) =>{
    let editedIx = 0
    const editedData = data.data.map((el, ix) =>{
      if(identifier === el['identifier']){
        editedIx = ix;
        let transformFunc = getTransformFunc(columns[ix].type)
        if(!(el as any).highlighted){
          (el as any).top = -1;// //reset top
        }
        return {...el, 'value': transformFunc(newValue)}
      }
      if((el as any).highlighted){
        (el as any).top = -1;//reset top and force control to move back
        return {...el};
      }
      return el;
    })

    setData({...data, data: editedData})
    const whereToSlice = Math.max(editedIx, inputText.split(splitWord).length)

    //filter empty items  no need for empty next
    const filteredData: { value: any; identifier: string; name: string; }[] = [];
    let foundValue = false;
    [...editedData].reverse().forEach((el) => {
      if(!foundValue && !el.value){
        return;
      }
      foundValue = true;
      filteredData.push(el);
    });
    filteredData.reverse();
    const newInput = filteredData.map((el) => el['value']).slice(0, whereToSlice + 1).join(" " + splitWord + " ");
    setInputText(newInput);
  }

  const submitEntry = (e:any) => {
    e.preventDefault()
    saveAndReset(data)
  }

  const saveAndReset = (data: any) => {
    if(secondBarcode){
      data = {...data, data: [{
        identifier: secondBarcode.identifier, 
        name: secondBarcode.name, 
        value: secondBarcode?.currentScan, 
        top: -1, 
        highlighted: false}, ...data.data]}
      setSecondBarcode({...secondBarcode, currentScan: ""})
    }

    if(fixedBarcode){
      data = {...data, data: [{
        identifier: FIXED_BARCODE_COLUMN, 
        name: FIXED_BARCODE_COLUMN, 
        value: fixedBarcode!.currentScan, 
        top: -1, 
        highlighted: false}, ...data.data]}
    }

    saveEntry(data, currentUser)
    setInputText("")
    setData(dataFromColumns(columns))
  }

  return (
    <div className="App d-flex flex-column h-100">
      <h3 className="my-3 container">{getTranslation("data_entry_title")}</h3>
      {loading ? (
        <div className="container">
          <div className="spinner-border" style={{width: "5rem", "height": "5rem"}} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>):(
          <>
                  {fixedBarcode && <BarcodeInput barcode={fixedBarcode} setBarcode = {setBarcode} footerRefCurrent={footerRef.current} />}
                  {secondBarcode && <BarcodeInput barcode={secondBarcode} setBarcode = {setSecondBarcode} footerRefCurrent={footerRef.current} />}
                  <FormInputs row={data} manualChange={manualChange} barcode={fixedBarcode} />
          </>
        )
    }

    <footer className="footer mt-auto p-3 bg-light">
      <form className="row" onSubmit={submitEntry}>
        <div className="input-group">
          <textarea ref={footerRef}
            tabIndex={-1}
            autoComplete='off'
            disabled={fixedBarcode ? !fixedBarcode.currentScan : false}
            placeholder='your text here...'
            className="form-control shadow-none" onChange={onChange} value={inputText} />

          <button type="submit" className="btn btn-primary">{getTranslation("submit_data")}</button>
        </div>
      </form>
    </footer>

    </div>
  );
}

function FormInputs({row, barcode, manualChange}:{row: EntryRow, barcode?: BarcodeItem, manualChange: any}){
  return (
      <form className="container flex-shrink-0" style={{maxHeight: "77%", overflow: "auto"}}>
        {row.data.map((c) => (
            <FormInput data={c} manualChange={manualChange} key={c.identifier} barcode={barcode}/>
        ))}
      </form>
  );
}

export default App;
