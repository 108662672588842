import {useState} from "react";
import {getColumns, getEntries, EntryRow, deleteEntry} from "./db"
import { useAuth } from "./AuthContext"
import { useEffect } from "react";
import { Item } from "./type_declarations";
import getTranslation from "./translations";
import { BARCODE_COLUMN, FIXED_BARCODE_COLUMN } from './common';

const XLSX = require("xlsx");


function EntriesList(){
    const {currentUser} = useAuth();
    const [entries, setEntries] = useState<EntryRow[]>([])
    const [columns, setColumns] = useState<Item[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() =>{
        getEntries(currentUser, (entries: EntryRow[])=>{
            setEntries(entries)
            setLoading(false)
        });
        getColumns(currentUser, (columns: Item[]) =>{
            let ordered_columns = []
            let fixed_barcode_col = null;
            let barcode_col = null;
            for(let i=0;i<columns.length;i++){
                let column = columns[i];
                if(column.type === FIXED_BARCODE_COLUMN){
                    fixed_barcode_col = column
                } else if(column.type === BARCODE_COLUMN){
                    barcode_col = column
                } else {
                    ordered_columns.push(column)
                }
            }
            if(barcode_col){
                ordered_columns = [barcode_col, ...ordered_columns]
            }
            if(fixed_barcode_col){
                ordered_columns = [fixed_barcode_col, ...ordered_columns]
            }

            setColumns(ordered_columns);
            setLoading(false)
        });
    },[])

    const downloadCSV = () => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Rinkt Data",
            Subject: "Data",
            Author: "Rinkt",
        };
        const allData = [];
        let ordered_columns = []
        let fixed_barcode_col = null;
        let barcode_col = null;
        for(let i=0;i<columns.length;i++){
            let column = columns[i];
            if(column.type === FIXED_BARCODE_COLUMN){
               fixed_barcode_col = column
            } else if(column.type === BARCODE_COLUMN){
                barcode_col = column
            } else {
                ordered_columns.push(column)
            }
        }
        if(barcode_col){
            ordered_columns = [barcode_col, ...ordered_columns]
        }
        if(fixed_barcode_col){
            ordered_columns = [fixed_barcode_col, ...ordered_columns]
        }

        const headers = ordered_columns.map((c)=>`${c.name}`);
        allData.push(headers);
        entries.map((row) => allData.push(row.data.map(c => c.value)));
        const ws = XLSX.utils.aoa_to_sheet(allData);
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "RinktInputs.xlsx", {bookType:'xlsx',  type: 'binary', compression: true});
    }

    const deleteEntryClient = (entryId: string) => {
        const newEntries = [...entries.filter((el) => el.uid != entryId)]
        setEntries(newEntries)
        if(entryId !== ""){
            deleteEntry(currentUser, entryId)
        }
    }


    return (<div className="container table-responsive">
      <div className="my-3 d-flex justify-content-between">
        <h3>{getTranslation("entry_list_header")}</h3>
        <a href="#" onClick={downloadCSV}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-csv" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"/>
           </svg>
           &nbsp;Export to Excel
            </a>
      </div>
      {loading ? (
  <div className="spinner-border" style={{width: "5rem", "height": "5rem"}} role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
):(
        <table className="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                {columns.map((c, ix) => (
                    <th scope="col" key={c.identifier}>{c.name}</th>
                ))}
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            {entries.map((e, ix) => (
                <tr key={ix}>
                    <th scope="row">
                        {ix}
                    </th>
                    {e.data.map((entry) => (<td key={entry.identifier}>{entry.value}</td>))}
                    <th scope="row">
                        <a href="#" onClick={(_) => deleteEntryClient(e.uid || "")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                        </a>
                    </th>
                </tr>
            ))}
        </tbody>
        </table>
)}
    </div>)
}

export default EntriesList;
